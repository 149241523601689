/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-web/fonts/#font-display
 */

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('../assets/fonts/ProximaNovaAlt/regular-webfont.woff') format('woff'),
    url('../assets/fonts/ProximaNovaAlt/regular-webfont.ttf') format('truetype'),
    url('../assets/fonts/ProximaNovaAlt/regular-webfont.eot?#iefix')
    format('embedded-opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('../assets/fonts/ProximaNovaAlt/bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaAlt/bold-webfont.woff') format('woff'),
    url('../assets/fonts/ProximaNovaAlt/bold-webfont.ttf') format('truetype'),
    url('../assets/fonts/ProximaNovaAlt/bold-webfont.eot?#iefix')
    format('embedded-opentype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('../assets/fonts/ProximaNovaAlt/semibold-webfont.woff2')
    format('woff2'),
    url('../assets/fonts/ProximaNovaAlt/semibold-webfont.ttf')
    format('truetype'),
    url('../assets/fonts/ProximaNovaAlt/semibold-webfont.eot?#iefix')
    format('embedded-opentype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('../assets/fonts/ProximaNovaAlt/light-webfont.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaAlt/light-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
