.c-home-ready-to-win {
  padding: 0 30px;

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }

  @include bp(medium) {
    padding-left: 74px;
    padding-right: 74px;

    p {
      font-size: 18px;
      line-height: 22px;
    }
  }

  @include bp(full) {
    padding-left: 344px;
    padding-right: 344px;

    p {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.c-home-ready-to-win__heading {
  margin-bottom: 30px;
  font-weight: 300;

  @include bp(full) {
    margin-bottom: 40px;
  }
}

.c-home-ready-to-win__link {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 0.1pt solid currentColor;
}

.c-home-ready-to-win__start-learning {
  margin-top: 30px;

  text-align: center;

  @include bp(full) {
    margin-top: 40px;
  }
}
