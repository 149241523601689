@charset "UTF-8";
/*
  Project: Cais IQ Challenge
  Author: Xfive
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-web/fonts/#font-display
 */
@font-face {
  font-family: 'Proxima Nova Alt';
  src: url("../assets/fonts/ProximaNovaAlt/regular-webfont.woff") format("woff"), url("../assets/fonts/ProximaNovaAlt/regular-webfont.ttf") format("truetype"), url("../assets/fonts/ProximaNovaAlt/regular-webfont.eot?#iefix") format("embedded-opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url("../assets/fonts/ProximaNovaAlt/bold-webfont.woff2") format("woff2"), url("../assets/fonts/ProximaNovaAlt/bold-webfont.woff") format("woff"), url("../assets/fonts/ProximaNovaAlt/bold-webfont.ttf") format("truetype"), url("../assets/fonts/ProximaNovaAlt/bold-webfont.eot?#iefix") format("embedded-opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url("../assets/fonts/ProximaNovaAlt/semibold-webfont.woff2") format("woff2"), url("../assets/fonts/ProximaNovaAlt/semibold-webfont.ttf") format("truetype"), url("../assets/fonts/ProximaNovaAlt/semibold-webfont.eot?#iefix") format("embedded-opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url("../assets/fonts/ProximaNovaAlt/light-webfont.woff2") format("woff2"), url("../assets/fonts/ProximaNovaAlt/light-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, .c-home-counter__heading-text, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

strong, b {
  font-weight: bold;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, .c-home-counter__heading-text, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

body {
  width: 100%;
  overflow-x: hidden;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 40px;
  line-height: 49px;
  color: #2698c6;
}

@media (min-width: 768px) {
  h1 {
    font-size: 48px;
    line-height: 58px;
  }
}

@media (min-width: 1440px) {
  h1 {
    font-size: 52px;
    line-height: 63px;
  }
}

h2 {
  font-size: 36px;
  line-height: 44px;
}

@media (min-width: 768px) {
  h2 {
    font-size: 46px;
    line-height: 56px;
  }
}

@media (min-width: 1440px) {
  h2 {
    font-size: 52px;
    line-height: 63px;
  }
}

h3, .c-home-counter__heading-text {
  font-size: 28px;
  line-height: 34px;
}

@media (min-width: 768px) {
  h3, .c-home-counter__heading-text {
    font-size: 40px;
    line-height: 49px;
  }
}

@media (min-width: 1440px) {
  h3, .c-home-counter__heading-text {
    font-size: 52px;
    line-height: 63px;
  }
}

h4 {
  font-size: 20px;
  line-height: 32px;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-family: "Proxima Nova Alt", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1440px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-circle {
  background-image: url("../assets/images/element-circle.svg");
  background-color: transparent;
  background-size: 100%;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
}

.o-circle::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.o-circle--small {
  width: 107px;
}

@media (min-width: 768px) {
  .o-circle--small {
    width: 248px;
  }
}

.o-circle--large {
  width: 261px;
}

@media (min-width: 768px) {
  .o-circle--large {
    width: 457px;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-btn {
  padding: 0 30px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  background-color: #2698c6;
  border-radius: 70px;
  border: 0;
  cursor: pointer;
}

@media (min-width: 768px) {
  .c-btn {
    padding-left: 44px;
    padding-right: 44px;
    height: 50px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 100px;
  }
}

.c-countdown {
  display: block;
  text-align: center;
  font-size: 40px;
  line-height: 1.2223;
  font-weight: 300;
  color: #2698c6;
}

@media (min-width: 768px) {
  .c-countdown {
    font-size: 60px;
  }
}

@media (min-width: 1440px) {
  .c-countdown {
    font-size: 90px;
  }
}

.c-countdown__part {
  display: inline-block;
  width: 1ch;
}

.c-footer {
  color: #fff;
  background-color: #0d1b29;
}

.c-footer__grid {
  padding: 40px 30px;
  display: grid;
  grid-template-areas: 'logo' 'socials' 'links' 'disclaimer' 'copyright';
}

@media (min-width: 768px) {
  .c-footer__grid {
    padding: 55px 74px 40px;
    grid-template-areas: 'logo logo' 'disclaimer socials' 'links copyright';
  }
}

.c-footer__logo {
  margin-bottom: 30px;
  grid-area: logo;
  text-align: center;
}

.c-footer__logo img {
  width: 112px;
}

@media (min-width: 768px) {
  .c-footer__logo {
    text-align: left;
  }
  .c-footer__logo img {
    width: 135px;
  }
}

@media (min-width: 1440px) {
  .c-footer__logo {
    padding-left: 7px;
  }
  .c-footer__logo img {
    width: 172px;
  }
}

.c-footer__socials {
  grid-area: socials;
  margin: 0 0 20px 0;
  padding: 0;
  text-align: center;
  list-style: none;
}

.c-footer__socials li {
  display: inline-block;
}

.c-footer__socials li:not(:last-child) {
  margin-right: 15px;
}

@media (min-width: 768px) {
  .c-footer__socials {
    margin: 0;
    text-align: right;
  }
}

@media (min-width: 1440px) {
  .c-footer__socials img {
    width: 24px;
  }
}

.c-footer__links {
  grid-area: links;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}

.c-footer__links a {
  text-decoration: none;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #fff;
}

.c-footer__links a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .c-footer__links {
    text-align: left;
  }
  .c-footer__links li {
    display: inline-block;
  }
  .c-footer__links li:not(:last-child) {
    margin-right: 15px;
  }
  .c-footer__links a {
    font-size: 12px;
    line-height: 15px;
  }
}

.c-footer__disclaimer {
  grid-area: disclaimer;
  margin-top: 40px;
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.01em;
}

@media (min-width: 768px) {
  .c-footer__disclaimer {
    margin-top: 0;
    margin-bottom: 45px;
    text-align: left;
  }
}

@media (min-width: 1440px) {
  .c-footer__disclaimer {
    max-width: 567px;
    font-size: 10px;
    line-height: 12px;
  }
}

.c-footer__copyright {
  grid-area: copyright;
  margin-top: 40px;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.01em;
}

@media (min-width: 768px) {
  .c-footer__copyright {
    margin-top: 0;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
  }
}

.c-form {
  margin: 0 auto;
  max-width: 290px;
}

@media (min-width: 1440px) {
  .c-form {
    max-width: 700px;
  }
}

.c-form__fieldset {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .c-form__fieldset {
    margin-bottom: 42px;
  }
}

.c-form__fields {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  justify-content: space-between;
}

.c-form__field {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 12px;
}

@media (min-width: 1440px) {
  .c-form__field {
    width: calc(50% - 15px);
  }
}

.c-form__label {
  display: block;
  margin: 8px 0;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-form__label {
    font-size: 12px;
    line-height: 1.25;
  }
}

.c-form__text {
  display: block;
  padding: 8px 15px;
  width: 100%;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.3px;
  background: #fff;
  color: #333155;
}

@media (min-width: 1440px) {
  .c-form__text {
    padding: 15px 20px;
    font-size: 14px;
    line-height: 1.3;
  }
}

.c-form__text:focus {
  outline: #2698c6 auto 5px;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 2px;
}

.c-form__text:focus:not(:focus-visible) {
  outline: none;
}

.c-form__text:focus-visible {
  outline: #2698c6 auto 5px;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 2px;
}

.c-form__legend {
  display: block;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: 1px;
}

.c-form__inline-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  list-style: none;
}

.c-form__inline-field {
  margin: 0 15px 12px;
}

.c-form__inline-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.c-form__checkbox {
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0 5px 0 0;
  border: 2px solid #fff;
  border-radius: 3px;
  background: transparent;
  opacity: 0;
  z-index: 1;
}

.c-form__box-label {
  position: relative;
}

.c-form__box-label[title] {
  text-decoration: none;
}

.c-form__box-label::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  display: block;
  width: 18px;
  height: 18px;
  margin: -10px 5px 0 0;
  border: 2px solid currentColor;
  border-radius: 3px;
  background: transparent;
}

.c-form__checkbox:checked + .c-form__box-label::before {
  background: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.1 6.78L10.58.46a1 1 0 011.4-.1 1 1 0 01.1 1.4L5.42 9.46.54 6.01A1 1 0 01.3 4.6a1 1 0 011.39-.24l3.41 2.4z' fill='%23424B5A'/%3E%3C/svg%3E") center no-repeat currentColor;
}

input:focus + .c-form__box-label {
  outline: #2698c6 auto 5px;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 2px;
}

input:focus:not(:focus-visible) + .c-form__box-label {
  outline: none;
}

input:focus-visible + .c-form__box-label {
  outline: #2698c6 auto 5px;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 2px;
}

.c-form__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  margin-bottom: 2em;
  padding-bottom: 1em;
}

.c-home-about {
  margin-top: 80px;
  color: #fff;
  background-color: #2698c6;
}

.c-home-about__inner {
  padding: 80px 30px;
  text-align: center;
}

.c-home-about__inner p {
  font-size: 14px;
  line-height: 17px;
}

.c-home-about__inner a {
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
}

.c-home-about__inner a:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-home-about__inner {
    padding-left: 74px;
    padding-right: 74px;
  }
  .c-home-about__inner p {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (min-width: 1440px) {
  .c-home-about__inner {
    padding-left: 344px;
    padding-right: 344px;
  }
  .c-home-about__inner p {
    font-size: 20px;
    line-height: 24px;
  }
}

.c-home-about__description {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.c-home-about__heading {
  margin: 20px 0;
  font-weight: 300;
}

.c-home-counter {
  margin: 40px auto 0;
  padding: 0 30px;
}

@media (min-width: 768px) {
  .c-home-counter {
    padding-right: 74px;
    padding-left: 74px;
    margin-top: 60px;
  }
}

@media (min-width: 1440px) {
  .c-home-counter {
    margin-top: 100px;
  }
}

.c-home-counter__heading {
  max-width: 500px;
  margin: 0 auto 40px;
  position: relative;
}

@media (min-width: 768px) {
  .c-home-counter__heading {
    max-width: 750px;
  }
}

@media (min-width: 1440px) {
  .c-home-counter__heading {
    margin-bottom: 70px;
    margin-left: 248px;
    margin-right: 248px;
  }
}

.c-home-counter__heading-text {
  font-weight: 300;
  text-align: center;
  color: #0d1b29;
}

.c-home-counter__heading-text--background {
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: scaleX(1.05);
}

.c-home-counter__heading-text--background span {
  color: transparent;
  background: linear-gradient(transparent 45%, rgba(241, 142, 6, 0.5) 45%);
}

@media (min-width: 768px) {
  .c-home-counter__heading-text--background {
    transform: scaleX(1.1);
  }
}

@media (min-width: 1440px) {
  .c-home-counter__heading-text--background span {
    background: linear-gradient(transparent 35%, rgba(241, 142, 6, 0.5) 35%);
  }
}

.c-home-counter__heading-text time {
  font-weight: 400;
}

.c-home-counter__signup {
  margin-top: 40px;
  margin-bottom: 80px;
  text-align: center;
}

.c-home-counter__disclaimer {
  margin: 80px auto 80px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

@media (min-width: 1440px) {
  .c-home-counter__disclaimer {
    margin-bottom: 100px;
    padding-right: 270px;
    padding-left: 270px;
  }
}

.c-home-hero {
  height: 278px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  background-color: #0d1b29;
}

@media (min-width: 1440px) {
  .c-home-hero {
    height: 308px;
  }
}

.c-home-hero__circle {
  position: absolute;
}

.c-home-hero__circle--large {
  width: 145px;
  top: 188px;
  left: -87px;
}

.c-home-hero__circle--small {
  width: 100px;
  right: -48px;
  bottom: -32px;
}

@media (min-width: 768px) {
  .c-home-hero__circle--large {
    width: 277px;
    top: 76px;
    left: -141px;
  }
  .c-home-hero__circle--small {
    width: 221px;
    right: -153px;
    bottom: -82px;
  }
}

@media (min-width: 1440px) {
  .c-home-hero__circle--large {
    width: 457px;
    top: -74px;
    left: -212px;
  }
  .c-home-hero__circle--small {
    width: 248px;
    right: -160px;
    bottom: -102px;
  }
}

.c-home-hero__logo {
  width: 160px;
  display: block;
}

@media (min-width: 768px) {
  .c-home-hero__logo {
    width: 221px;
  }
}

@media (min-width: 1440px) {
  .c-home-hero__logo {
    width: 237px;
  }
}

.c-home-intro {
  margin: 80px auto 0;
  padding: 0 30px;
  max-width: 1136px;
}

.c-home-intro p {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .c-home-intro {
    padding-right: 76px;
    padding-left: 76px;
  }
  .c-home-intro p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1440px) {
  .c-home-intro {
    margin-top: 100px;
    padding-right: 0;
    padding-left: 0;
  }
  .c-home-intro p {
    margin: 0 200px 1.3rem;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
}

.c-home-intro__heading {
  margin-bottom: 20px;
  font-weight: normal;
  text-align: center;
}

@media (min-width: 1440px) {
  .c-home-intro__heading {
    margin-bottom: 40px;
  }
}

.c-home-join-us {
  margin-bottom: 80px;
  color: #fff;
  background-color: #0d1b29;
}

.c-home-join-us__inner {
  display: grid;
  grid-template-areas: 'content';
  position: relative;
}

@media (min-width: 768px) {
  .c-home-join-us__inner {
    grid-template-areas: 'content image';
    grid-template-columns: 58% 38%;
    column-gap: 30px;
  }
}

@media (min-width: 1440px) {
  .c-home-join-us__inner {
    margin-bottom: 120px;
    grid-template-columns: 1fr 1fr;
    column-gap: 140px;
  }
}

.c-home-join-us__image {
  display: none;
  width: 100%;
  justify-self: end;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

@media (min-width: 768px) {
  .c-home-join-us__image {
    grid-area: image;
    display: block;
    background-image: url("../assets/images/img-SanDiego-sm.jpg");
  }
}

@media (min-width: 1440px) {
  .c-home-join-us__image {
    background-image: url("../assets/images/img-SanDiego.jpg");
  }
}

.c-home-join-us__content {
  grid-area: content;
  padding: 40px 30px 40px 30px;
}

@media (min-width: 768px) {
  .c-home-join-us__content {
    padding: 80px 0 80px 74px;
  }
}

@media (min-width: 1440px) {
  .c-home-join-us__content {
    padding: 95px 0 95px 152px;
  }
}

.c-home-join-us__circle {
  width: 125px;
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .c-home-join-us__circle {
    width: 220px;
    display: block;
    bottom: -117px;
    left: -115px;
    background-image: url("../assets/images/element-circle-light.svg");
  }
}

@media (min-width: 1440px) {
  .c-home-join-us__circle {
    width: 248px;
    bottom: -112px;
    left: -86px;
    background-image: url("../assets/images/element-circle.svg");
  }
}

.c-home-join-us__heading {
  font-weight: 300;
}

.c-home-join-us__subheading {
  margin: 20px 0;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #2698c6;
}

@media (min-width: 1440px) {
  .c-home-join-us__subheading {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.c-home-join-us__title {
  font-size: 16px;
  font-style: italic;
  line-height: 19px;
  letter-spacing: 0.02em;
}

@media (min-width: 768px) {
  .c-home-join-us__title {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (min-width: 1440px) {
  .c-home-join-us__title {
    font-size: 20px;
    line-height: 24px;
  }
}

.c-home-join-us__date {
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}

@media (min-width: 768px) {
  .c-home-join-us__date {
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  .c-home-join-us__date {
    margin-top: 40px;
    font-size: 16px;
  }
}

.c-home-ready-to-win {
  padding: 0 30px;
}

.c-home-ready-to-win p {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

@media (min-width: 768px) {
  .c-home-ready-to-win {
    padding-left: 74px;
    padding-right: 74px;
  }
  .c-home-ready-to-win p {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (min-width: 1440px) {
  .c-home-ready-to-win {
    padding-left: 344px;
    padding-right: 344px;
  }
  .c-home-ready-to-win p {
    font-size: 20px;
    line-height: 24px;
  }
}

.c-home-ready-to-win__heading {
  margin-bottom: 30px;
  font-weight: 300;
}

@media (min-width: 1440px) {
  .c-home-ready-to-win__heading {
    margin-bottom: 40px;
  }
}

.c-home-ready-to-win__link {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 0.1pt solid currentColor;
}

.c-home-ready-to-win__start-learning {
  margin-top: 30px;
  text-align: center;
}

@media (min-width: 1440px) {
  .c-home-ready-to-win__start-learning {
    margin-top: 40px;
  }
}

.c-modal {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 70px 30px;
  width: 100%;
  background: #0d1b29;
  color: #fff;
}

@media (min-width: 768px) {
  .c-modal {
    position: fixed;
    padding: 70px 30px;
    width: 620px;
    max-height: 100vh;
    overflow: auto;
  }
}

@media (min-width: 1440px) {
  .c-modal {
    padding: 100px 80px;
    width: 860px;
  }
}

.c-modal.is-active {
  display: block;
  transform: translate(-50%, 0);
}

@media (min-width: 768px) {
  .c-modal.is-active {
    transform: translate(-50%, -50%);
  }
}

.c-modal__close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  top: 18px;
  right: 18px;
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.c-thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: auto;
}

.c-thank-you__header {
  max-width: 360px;
  margin: 0 auto 20px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.c-thank-you__content {
  max-width: 360px;
  margin: 0 auto 20px;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.4px;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}
