.c-home-about {
  margin-top: 80px;
  color: $color-about-text;
  background-color: $color-about-bg;
}

.c-home-about__inner {
  padding: 80px 30px;

  text-align: center;

  p {
    font-size: 14px;
    line-height: 17px;
  }

  a {
    color: $color-about-text;
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }

  @include bp(medium) {
    padding-left: 74px;
    padding-right: 74px;

    p {
      font-size: 18px;
      line-height: 22px;
    }
  }

  @include bp(full) {
    padding-left: 344px;
    padding-right: 344px;

    p {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.c-home-about__description {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.c-home-about__heading {
  margin: 20px 0;

  font-weight: 300;
}
