/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: 40px;
  line-height: 49px;
  color: $color-text-heading;

  @include bp(medium) {
    font-size: 48px;
    line-height: 58px;
  }

  @include bp(full) {
    font-size: 52px;
    line-height: 63px;
  }
}

h2 {
  font-size: 36px;
  line-height: 44px;

  @include bp(medium) {
    font-size: 46px;
    line-height: 56px;
  }

  @include bp(full) {
    font-size: 52px;
    line-height: 63px;
  }
}

h3 {
  font-size: 28px;
  line-height: 34px;

  @include bp(medium) {
    font-size: 40px;
    line-height: 49px;
  }

  @include bp(full) {
    font-size: 52px;
    line-height: 63px;
  }
}

h4 {
  font-size: 20px;
  line-height: 32px;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}
