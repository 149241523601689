.c-form {
  margin: 0 auto;
  max-width: 290px;

  @include bp(full) {
    max-width: 700px;
  }
}

.c-form__fieldset {
  margin-bottom: 30px;

  @include bp(medium) {
    margin-bottom: 42px;
  }
}

.c-form__fields {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  justify-content: space-between;
}

.c-form__field {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 12px;

  @include bp(full) {
    width: calc(50% - 15px);
  }
}

.c-form__label {
  display: block;
  margin: 8px 0;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;

  @include bp(medium) {
    font-size: 12px;
    line-height: 1.25;
  }
}

.c-form__text {
  display: block;
  padding: 8px 15px;
  width: 100%;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.3px;
  background: $color-white;
  color: #333155;

  @include bp(full) {
    padding: 15px 20px;
    font-size: 14px;
    line-height: 1.3;
  }

  &:focus {
    outline: $color-blue auto 5px;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: $color-blue auto 5px;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: 2px;
  }
}

.c-form__legend {
  display: block;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: 1px;
}

.c-form__inline-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  list-style: none;
}

.c-form__inline-field {
  margin: 0 15px 12px;
}

.c-form__inline-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.c-form__checkbox {
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0 5px 0 0;
  border: 2px solid $color-white;
  border-radius: 3px;
  background: transparent;
  opacity: 0;
  z-index: 1;
}

.c-form__box-label {
  position: relative;

  &[title] {
    text-decoration: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    display: block;
    width: 18px;
    height: 18px;
    margin: -10px 5px 0 0;
    border: 2px solid currentColor;
    border-radius: 3px;
    background: transparent;
  }

  .c-form__checkbox:checked + &::before {
    background: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.1 6.78L10.58.46a1 1 0 011.4-.1 1 1 0 01.1 1.4L5.42 9.46.54 6.01A1 1 0 01.3 4.6a1 1 0 011.39-.24l3.41 2.4z' fill='%23424B5A'/%3E%3C/svg%3E")
      center no-repeat currentColor;
  }

  input:focus + & {
    outline: $color-blue auto 5px;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: 2px;
  }

  input:focus:not(:focus-visible) + & {
    outline: none;
  }

  input:focus-visible + & {
    outline: $color-blue auto 5px;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: 2px;
  }
}

.c-form__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}
