.c-modal {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 70px 30px;
  width: 100%;
  background: $color-dark-grey;
  color: $color-white;

  @include bp(medium) {
    position: fixed;
    padding: 70px 30px;
    width: 620px;
    max-height: 100vh;
    overflow: auto;
  }

  @include bp(full) {
    padding: 100px 80px;
    width: 860px;
  }

  &.is-active {
    display: block;
    transform: translate(-50%, 0);

    @include bp(medium) {
      transform: translate(-50%, -50%);
    }
  }
}

.c-modal__close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  top: 18px;
  right: 18px;
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
}
