.c-footer {
  color: $color-footer-text;
  background-color: $color-footer-bg;
}

.c-footer__grid {
  padding: 40px 30px;
  display: grid;
  grid-template-areas: 'logo' 'socials' 'links' 'disclaimer' 'copyright';

  @include bp(medium) {
    padding: 55px 74px 40px;

    grid-template-areas: 'logo logo' 'disclaimer socials' 'links copyright';
  }
}

.c-footer__logo {
  margin-bottom: 30px;

  grid-area: logo;

  text-align: center;

  img {
    width: 112px;
  }

  @include bp(medium) {
    text-align: left;

    img {
      width: 135px;
    }
  }

  @include bp(full) {
    padding-left: 7px;

    img {
      width: 172px;
    }
  }
}

.c-footer__socials {
  grid-area: socials;

  margin: 0 0 20px 0;
  padding: 0;

  text-align: center;
  list-style: none;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  @include bp(medium) {
    margin: 0;

    text-align: right;
  }

  @include bp(full) {
    img {
      width: 24px;
    }
  }
}

.c-footer__links {
  grid-area: links;

  margin: 0;
  padding: 0;

  text-align: center;
  list-style: none;

  a {
    text-decoration: none;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: $color-footer-link;

    &:hover {
      text-decoration: underline;
    }
  }

  @include bp(medium) {
    text-align: left;

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    a {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.c-footer__disclaimer {
  grid-area: disclaimer;

  margin-top: 40px;

  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.01em;

  @include bp(medium) {
    margin-top: 0;
    margin-bottom: 45px;
    text-align: left;
  }

  @include bp(full) {
    max-width: 567px;

    font-size: 10px;
    line-height: 12px;
  }
}

.c-footer__copyright {
  grid-area: copyright;

  margin-top: 40px;

  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.01em;

  @include bp(medium) {
    margin-top: 0;

    font-size: 12px;
    line-height: 15px;
    text-align: right;
  }
}
