.c-home-intro {
  margin: 80px auto 0;
  padding: 0 30px;
  max-width: 1136px;

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  @include bp(medium) {
    padding-right: 76px;
    padding-left: 76px;

    p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @include bp(full) {
    margin-top: 100px;
    padding-right: 0;
    padding-left: 0;

    p {
      margin: 0 200px 1.3rem;
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }
  }
}

.c-home-intro__heading {
  margin-bottom: 20px;

  font-weight: normal;
  text-align: center;

  @include bp(full) {
    margin-bottom: 40px;
  }
}
