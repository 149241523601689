.c-countdown {
  display: block;
  text-align: center;
  font-size: 40px;
  line-height: 1.2223;
  font-weight: 300;
  color: $color-blue;

  @include bp(medium) {
    font-size: 60px;
  }

  @include bp(full) {
    font-size: 90px;
  }
}

.c-countdown__part {
  display: inline-block;
  width: 1ch;
}
