.c-home-join-us {
  margin-bottom: 80px;
  color: $color-join-us-text;
  background-color: $color-join-us-bg;
}

.c-home-join-us__inner {
  display: grid;
  grid-template-areas: 'content';
  position: relative;

  @include bp(medium) {
    grid-template-areas: 'content image';
    grid-template-columns: 58% 38%;
    column-gap: 30px;
  }

  @include bp(full) {
    margin-bottom: 120px;
    grid-template-columns: 1fr 1fr;
    column-gap: 140px;
  }
}

.c-home-join-us__image {
  display: none;
  width: 100%;
  justify-self: end;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;

  @include bp(medium) {
    grid-area: image;
    display: block;
    background-image: url('../assets/images/img-SanDiego-sm.jpg');
  }

  @include bp(full) {
    background-image: url('../assets/images/img-SanDiego.jpg');
  }
}

.c-home-join-us__content {
  grid-area: content;
  padding: 40px 30px 40px 30px;

  @include bp(medium) {
    padding: 80px 0 80px 74px;
  }

  @include bp(full) {
    padding: 95px 0 95px 152px;
  }
}

.c-home-join-us__circle {
  width: 125px;

  position: absolute;
  display: none;

  @include bp(medium) {
    width: 220px;
    display: block;

    bottom: -117px;
    left: -115px;

    background-image: url('../assets/images/element-circle-light.svg');
  }

  @include bp(full) {
    width: 248px;

    bottom: -112px;
    left: -86px;

    background-image: url('../assets/images/element-circle.svg');
  }
}

.c-home-join-us__heading {
  font-weight: 300;
}

.c-home-join-us__subheading {
  margin: 20px 0;

  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: $color-text-heading;

  @include bp(full) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.c-home-join-us__title {
  font-size: 16px;
  font-style: italic;
  line-height: 19px;
  letter-spacing: 0.02em;

  @include bp(medium) {
    font-size: 18px;
    line-height: 22px;
  }

  @include bp(full) {
    font-size: 20px;
    line-height: 24px;
  }
}

.c-home-join-us__date {
  margin-top: 20px;

  font-size: 14px;
  font-weight: bold;
  line-height: 22px;

  @include bp(medium) {
    font-size: 18px;
  }

  @include bp(full) {
    margin-top: 40px;

    font-size: 16px;
  }
}
