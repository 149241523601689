.c-thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: auto;
}

.c-thank-you__header {
  max-width: 360px;
  margin: 0 auto 20px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.c-thank-you__content {
  max-width: 360px;
  margin: 0 auto 20px;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.4px;
}
