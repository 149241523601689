.c-btn {
  padding: 0 30px;
  height: 34px;
  display: inline-flex;
  align-items: center;

  font-family: inherit;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;

  color: $color-btn-text;
  background-color: $color-btn-bg;
  border-radius: 70px;
  border: 0;
  cursor: pointer;

  @include bp(medium) {
    padding-left: 44px;
    padding-right: 44px;
    height: 50px;

    font-size: 14px;
    line-height: 22px;

    border-radius: 100px;
  }
}
