.c-home-counter {
  margin: 40px auto 0;
  padding: 0 30px;

  @include bp(medium) {
    padding-right: 74px;
    padding-left: 74px;
    margin-top: 60px;
  }

  @include bp(full) {
    margin-top: 100px;
  }
}

.c-home-counter__heading {
  max-width: 500px;
  margin: 0 auto 40px;
  position: relative;

  @include bp(medium) {
    max-width: 750px;
  }

  @include bp(full) {
    margin-bottom: 70px;
    margin-left: 248px;
    margin-right: 248px;
  }
}

.c-home-counter__heading-text {
  font-weight: 300;
  text-align: center;
  color: $color-counter-heading-text;

  @extend h3;

  &--background {
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(1.05);

    span {
      color: transparent;
      background: linear-gradient(
        transparent 45%,
        rgba($color-orange, 0.5) 45%
      );
    }

    @include bp(medium) {
      transform: scaleX(1.1);
    }

    @include bp(full) {
      span {
        background: linear-gradient(
          transparent 35%,
          rgba($color-orange, 0.5) 35%
        );
      }
    }
  }

  time {
    font-weight: 400;
  }
}

.c-home-counter__signup {
  margin-top: 40px;
  margin-bottom: 80px;
  text-align: center;
}

.c-home-counter__disclaimer {
  margin: 80px auto 80px;

  font-size: 14px;
  font-weight: 300;
  line-height: 17px;

  @include bp(full) {
    margin-bottom: 100px;
    padding-right: 270px;
    padding-left: 270px;
  }
}
