.o-circle {
  background-image: url('../assets/images/element-circle.svg');
  background-color: transparent;
  background-size: 100%;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &--small {
    width: 107px;

    @include bp(medium) {
      width: 248px;
    }
  }

  &--large {
    width: 261px;

    @include bp(medium) {
      width: 457px;
    }
  }
}
