.c-home-hero {
  height: 278px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: visible;
  background-color: $color-header-bg;

  @include bp(full) {
    height: 308px;
  }
}

.c-home-hero__circle {
  position: absolute;

  &--large {
    width: 145px;
    top: 188px;
    left: -87px;
  }

  &--small {
    width: 100px;
    right: -48px;
    bottom: -32px;
  }

  @include bp(medium) {
    &--large {
      width: 277px;
      top: 76px;
      left: -141px;
    }

    &--small {
      width: 221px;
      right: -153px;
      bottom: -82px;
    }
  }

  @include bp(full) {
    &--large {
      width: 457px;
      top: -74px;
      left: -212px;
    }

    &--small {
      width: 248px;
      right: -160px;
      bottom: -102px;
    }
  }
}

.c-home-hero__logo {
  width: 160px;
  display: block;

  @include bp(medium) {
    width: 221px;
  }

  @include bp(full) {
    width: 237px;
  }
}
